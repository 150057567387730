import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  RouterProvider,
  Link,
  Outlet,
  useParams,
  useNavigate,
} from "react-router-dom";

import "./index.css";

import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady && onReady(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

const CENTER_PANEL = { maxWidth: 800, margin: "auto" };
const ACTION_LINK = {
  fontWeight: "bold",
  textDecoration: "underline",
  color: "black",
};
const NICER_INPUT = { marginRight: "1em" };
const INSET_DIV = { padding: "1em 2em" };

function prettyPrintFileSize(bytes) {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return size.toFixed(2) + " " + units[unitIndex];
}

const OAUTH_CLIENT_ID =
  "538532492958-i09k5cosbc8aflcenjotbkmvkfqvg0q5.apps.googleusercontent.com";

const ShareLinks = () => {
  const [loading, setLoading] = useState();
  const [out, setOut] = useState();

  return (
    <div style={INSET_DIV}>
      {out ? (
        ""
      ) : (
        <button
          onClick={() => {
            const fn = async () => {
              setLoading(true);

              const res = await (
                await fetch("/api/list-bucket?share=true")
              ).json();
              setOut(res);

              setLoading(false);
            };
            fn();
          }}
          disabled={loading}
        >
          {loading ? "Loading..." : "Manage share links"}
        </button>
      )}

      {!out ? (
        ""
      ) : (
        <table border={1}>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>links</th>
          </tr>
          {Object.entries(out).map(([uid, { title, links }]) => (
            <tr>
              <td>{uid}</td>
              <td>{title}</td>
              <td>
                {(links || []).map((share_token) => (
                  <span>
                    <a href={`/watch/${share_token}`} target="_blank">
                      {share_token.slice(0, 8)}
                    </a>{" "}
                  </span>
                ))}
                <button
                  disabled={loading}
                  onClick={() => {
                    const fn = async () => {
                      setLoading(true);
                      const res = await (
                        await fetch("/api/create-play-link", {
                          method: "POST",
                          body: JSON.stringify({ id: uid }),
                        })
                      ).json();

                      // mutate data model in-place
                      const newOut = { ...out };

                      newOut[uid].links = [
                        ...(newOut[uid].links || []),
                        res.share_token,
                      ];
                      setOut(newOut);
                      setLoading(false);
                    };
                    fn();
                  }}
                >
                  New link
                </button>{" "}
                <button
                  disabled={loading || !(links || []).length}
                  style={{ color: "red" }}
                  onClick={() => {
                    const fn = async () => {
                      setLoading(true);
                      for (const share_token of links) {
                        const res = await (
                          await fetch("/api/remove-play-link", {
                            method: "POST",
                            body: JSON.stringify({ id: share_token }),
                          })
                        ).json();
                      }

                      const newOut = { ...out };

                      newOut[uid].links = [];
                      setOut(newOut);
                      setLoading(false);
                    };

                    fn();
                  }}
                >
                  Clear links
                </button>
              </td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

const ListBucket = () => {
  const [loading, setLoading] = useState();
  const [out, setOut] = useState();

  return (
    <div style={INSET_DIV}>
      {out ? (
        ""
      ) : (
        <button
          onClick={() => {
            const fn = async () => {
              setLoading(true);

              const res = await (
                await fetch("/api/list-bucket?size=true")
              ).json();
              setOut(res);

              setLoading(false);
            };
            fn();
          }}
          disabled={loading}
        >
          {loading ? "Loading..." : "List storage bucket"}
        </button>
      )}

      {!out ? (
        ""
      ) : (
        <table border={1}>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>size</th>
          </tr>
          {Object.entries(out).map(([uid, { title, size }]) => (
            <tr>
              <td>{uid}</td>
              <td>{title}</td>
              <td>{prettyPrintFileSize(size || 0)}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

const VimeoImport = () => {
  const [vimeoUrl, setVimeoUrl] = useState("");
  const [vimeoPass, setVimeoPass] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [output, setOutput] = useState();

  return (
    <div style={INSET_DIV}>
      <input
        style={NICER_INPUT}
        placeholder="vimeo url"
        value={vimeoUrl}
        onChange={(e) => setVimeoUrl(e.target.value)}
      />
      <input
        style={NICER_INPUT}
        placeholder="vimeo password"
        value={vimeoPass}
        onChange={(e) => setVimeoPass(e.target.value)}
      />
      <button
        disabled={loading}
        onClick={() => {
          const fn = async () => {
            setOutput();
            setError();
            setLoading(true);

            try {
              const out = await (
                await fetch("/api/vimeo-dl", {
                  method: "POST",
                  body: JSON.stringify({ url: vimeoUrl, pass: vimeoPass }),
                })
              ).json();

              setOutput(out);
            } catch (e) {
              console.warn(e);
              setError(true);
            }

            setLoading(false);
          };

          fn();
        }}
      >
        {loading ? "downloading..." : "submit"}
      </button>
      {!error ? (
        ""
      ) : (
        <div style={{ marginTop: 10 }}>
          <b>Error. Check URL/password, or try again in a few minutes...</b>
        </div>
      )}
      {!output ? (
        ""
      ) : (
        <div style={{ marginTop: 10 }}>
          <i>
            Success! <b>{output.title}</b> is downloading from on the server.
            I&rsquo;ll send you an email when the download and transcoding is
            finished.
          </i>
        </div>
      )}
    </div>
  );
};

const App = () => {
  const [loading, setLoading] = useState(false);
  const [films, setFilms] = useState(false);
  const [encodedVideos, setEncodedVideos] = useState();
  const [shareLinks, setShareLinks] = useState();
  const [player, setPlayer] = useState();

  const { whoami, db } = useContext(DB);

  return (
    <div style={CENTER_PANEL}>
      <p style={{ textAlign: "right" }}>2024-09-06</p>
      <p style={{ textIndent: 0 }}>
        Dear Reem, Mohanad, Elhum, Omar, and Femke,
      </p>

      <p>
        I&rsquo;m on the Eurostar out of Brussels thinking of you all. As I
        didn&rsquo;t show this to you yet, I am left to convey it at distance,
        further and further by the moment.
      </p>

      <p>
        {" "}
        Firstly: the question of &ldquo;you.&rdquo; This raises the question of{" "}
        <i>authentication</i>, which my pirated OED tells me has an obsolete
        antecedent; the word &ldquo;authentic&rdquo; once meant &ldquo;entitled
        to obedience or respect.&rdquo; This is fitting enough to our
        discussions, I think, at least the respect part. Too many
        &ldquo;user&rdquo; systems take the drug addict analogy too far... I
        anticipate many conversations about how to model authentic people in our
        systems. At any rate, you seem to be <code>{whoami}</code>. If
        you&rsquo;re not happy with that, you can{" "}
        <a
          style={ACTION_LINK}
          href="#"
          onClick={() => {
            const fn = async () => {
              await fetch("/api/sign-out");
              window.location.reload();
            };
            fn();
          }}
        >
          sign out
        </a>
        .
      </p>
      <p>
        Wow, Paris already? That surprised me, the quickness of this journey. So
        let&rsquo;s get to it. You can add videos from Vimeo to the{" "}
        <i>storage bucket</i> of the PFI CDN, and then we&rsquo;ll transcode,
        serve, and embed them from here to bypass Vimeo's extortionate bandwidth
        pricing. Paste the Vimeo URL and password (if relevant) below to start:
      </p>
      <VimeoImport />

      <p>
        I started by writing a Google Drive importer. That seems less relevant
        for now, but we can keep it in mind if we want to host anything from the
        PFI Google Drive. The Vimeo importer uses{" "}
        <a href="https://github.com/yt-dlp/yt-dlp" target="_blank">
          yt-dlp
        </a>
        , which feels increasingly fragile: there are so many well-funded AI
        companies <i>scraping</i> the Internet these days that content
        repositories are tightening their controls. So, we may need to find
        other ways to import the source videos if this doesn&rsquo;t work
        reliably.
      </p>

      <p>
        Everything we import from Vimeo is then stored privately using Google
        Cloud Storage.{" "}
        <a href="https://cloud.google.com/storage/docs" target="_blank">
          They say
        </a>
        :{" "}
        <i>
          &ldquo;Cloud Storage allows world-wide storage and retrieval of any
          amount of data at any time.&rdquo;
        </i>{" "}
        Bold claims. Currently I&rsquo;m using multi-regional storage in the US,
        but we move the archive to the EU, to Asia,{" "}
        <a
          href="https://cloud.google.com/storage/pricing#regions"
          target="_blank"
        >
          etc
        </a>
        ... It{" "}
        <a
          href="https://cloud.google.com/storage/pricing#multi-regions"
          target="_blank"
        >
          costs
        </a>{" "}
        $0.026 per GB per month. So 100GB would cost $2.60/mo to store.
        Incidentally, the IDF uses Amazon's equivalent cloud storage
        extensively;{" "}
        <a
          href="https://www.972mag.com/cloud-israeli-army-gaza-amazon-google-microsoft/"
          target="_blank"
        >
          this 972mag reporting
        </a>{" "}
        indicates that &ldquo;billions of audio files&rdquo; (mass-surveilled
        phone calls?) are stored in Amazon S3 buckets. What a creepy archive.
      </p>

      <ListBucket />

      <p>
        Now that we have private storage and a <i>data egress</i> strategy from
        Vimeo: distribution! I&rsquo;m currently encoding the video to an h264{" "}
        <a
          href="https://en.wikipedia.org/wiki/HTTP_Live_Streaming"
          target="_blank"
        >
          HLS sequence
        </a>{" "}
        at 360p, 720p, and 1080p. This is a format that Apple made for the
        iPhone, and through their monopoly it seems by now to be the most
        universal way to disseminate video on the Internet. I can also add 2K
        and 4K encoding (probably using the av1 codec), but, for later.
        I&rsquo;m doing the transcoding with Google's <i>serverless</i> product,
        which bills{" "}
        <a href="https://cloud.google.com/run/pricing#tables" target="_blank">
          per-second
        </a>
        ; this way, it costs approximately $0.50 to encode an hour of video (a
        one-time cost per-video).
      </p>
      <p>
        Since the storage bucket is private, the next step is to{" "}
        <i>authenticate</i> access to the videos. My approach here is to
        generate <i>share tokens</i> that each grant access to one video. These
        tokens can be managed independently of the storage, so you could send
        someone a public link, and then decide a week later to remove their link
        without touching the underlying video in its storage bucket. We could
        also collect statistics on each share link&mdash;or not! Does this make
        sense? I realize that this sketch of epistolary infrastructure, is
        somewhere between banal and baffling, but I don&rsquo;t know exactly
        where it is on the elephant. For further discussion.
      </p>
      <ShareLinks />
      <p>
        The share links contain a full-screen video player based on{" "}
        <a href="https://videojs.com/" target="_blank">
          VideoJS
        </a>
        , which should be easy enough to style and customize as-needed. And
        then, for use on the PFI website, the share links can be embedded in an{" "}
        <code>&lt;iframe&gt;</code>, just as Vimeo videos are embedded. We may
        want to think through captions, titles, and poster frames while
        implementing this.{" "}
      </p>
      <p>
        As far as I understand the network pricing, bandwidth{" "}
        <a
          href="https://cloud.google.com/storage/pricing#network-egress"
          target="_blank"
        >
          costs
        </a>{" "}
        $0.12/GB. It actually gets{" "}
        <a href="https://cloud.google.com/cdn/pricing" target="_blank">
          a little cheaper
        </a>{" "}
        once we implement the CDN, which should also make things faster. I think
        this means it will cost us about $0.10 per hour of video streamed in
        high quality. So, filling a virutal movie theater with 150 people who
        watch an hour-long program in its entirety would cost us $15&mdash;the
        price of a single ticket. The{" "}
        <a
          href="https://help.vimeo.com/hc/en-us/articles/12426275404305-Bandwidth-on-Vimeo"
          target="_blank"
        >
          Vimeo bandwidth threshold
        </a>{" "}
        of 2TB indicates $200 of bandwidth on Google Cloud.
      </p>
      <p>
        I fear I'm wearing out my welcome at this cafe. Or else failing to
        appreciate my day in Paris... so: I&rsquo;ll wrap up this dispatch for
        now. Questions, testing, and feature requests are very welcome!
      </p>
      <p>Your correspondent,</p>
      <p>
        <i>R.M.O.</i>
      </p>
      <p>
        PS - did you know that <code>.ps</code> is the TLD for Palestine?
        It&rsquo;s my favorite TLD. A good next step here is to actually test
        this on the PFI website. There are several bits of clean-up that I
        should do before a very serious deployment (making a dedicated Google
        Cloud project and implementing the CDN, for instance), but my mind
        already leaps beyond these practicalities: is there a way that becoming
        reflexive about infrastructure decisions (and the ways that we feel
        failed by the status quo) is interesting to us? Would we want to offer
        this infrastructure to others?
      </p>
    </div>
  );
};

const Main = () => {
  return <Outlet />;
};

const Watch = () => {
  const { tokenId } = useParams();

  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  return (
    <VideoJS
      options={{
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
          {
            src: `/share/hls.m3u8?token=${tokenId}`,
            type: "application/x-mpegURL",
          },
        ],
      }}
      onReady={handlePlayerReady}
    />
  );
};

const DB = React.createContext({
  db: null,
  whoami: null,
});

const Auth = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authed, setAuthed] = useState(false);
  const [db, setDb] = useState();

  useEffect(() => {
    const fn = async () => {
      // 1. If a token is provided as `?token=XXX` forward it.
      try {
        const res = await (
          await fetch(`/api/db${window.location.search}`)
        ).json();

        setDb(res);
        setAuthed(true);

        // 3. Remove token from URL.
        console.log("resetting search...");
        //window.location.search = "";
        if (window.history && window.history.replaceState) {
          const newUrl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
          window.history.replaceState(null, "", newUrl);
        }
      } catch (e) {
        console.warn("auth failed", e);
      }

      setLoading(false);
    };

    fn();
  }, []);

  if (!authed) {
    return (
      <div style={CENTER_PANEL}>
        <p>
          {loading
            ? "Loading..."
            : "Hi! This part of the PFI CDN is restricted to authentic users."}
        </p>
      </div>
    );
  }

  return <DB.Provider value={db}>{children}</DB.Provider>;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "",
        element: (
          <Auth>
            <App />
          </Auth>
        ),
      },
      { path: "watch/:tokenId", element: <Watch /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
